<template>
  <ListContainerWidget
    id="posts-list"
    class="posts-list"
    :app-id="app.id"
    :view-more-location="getLocation('posts')"
    :size="getSize('posts')"
    hidden-actions
    stretch
  >
    <PostCreation
      v-if="canCreate"
      class="post-creation"
      enable-card-size="sm"
      :is-widget="true"
      @posts-updated="postListKey += 1"
    />
    <div class="border-top mt-2 mx-n2" />
    <PostsList
      :key="postListKey"
      class="posts-list "
      :is-widget="true"
      enable-card-size="xl"
    />
  </ListContainerWidget>
</template>

<script>
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import { checkPermissions } from '@/@core/utils/roles-utils';
// import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
// import PostCreation from '@/views/apps/posts/components/post-creation/PostCreation.vue';
// import PostsList from '@/views/apps/posts/components/PostsList.vue';

export default {
  name: 'PostsListWidget',

  components: { 
    ListContainerWidget: () => import('@core/widgets/ListContainerWidget.vue' /* webpackChunkName: "ListContainerWidget" */),
    PostCreation: () => import('@/views/apps/posts/components/post-creation/PostCreation.vue' /* webpackChunkName: "PostCreation" */),
    PostsList: () => import('@/views/apps/posts/components/PostsList.vue' /* webpackChunkName: "PostsList" */),
  },

  mixins: [WidgetLayoutMixin],

  data() {
    return {
      postListKey: 0,
    };
  },

  computed: {
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    canCreate() {
      return checkPermissions(
        'create',
        'post',
        this.loggedMemberRoles,
        this.$store.getters.currentCollective,
      );
    },
  }
};
</script>

<style lang="scss" scoped>
.landing-layout {
  .posts-list {
    max-height: 100vh;
    .post-creation,
    .posts-list {
      min-width: 75vw;
    }
  }
}
.posts-list {
  max-width: none !important;
}
.post-creation {
  max-width: none !important;
}
</style>
